// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K1NiCUR8{--padding-x:var(--spacing-md);--padding-y:var(--spacing-xs);align-items:center;background:var(--color-primary);color:var(--color-on-primary);display:flex;font-size:.81rem;font-weight:500;justify-content:space-between;line-height:1.38;margin-bottom:var(--spacing-lg);padding:0 var(--padding-x);padding-right:calc(var(--padding-x) - var(--spacing-xs))}@media(max-width:667.98px){.K1NiCUR8{--padding-x:var(--spacing-sm);--padding-y:var(--spacing-default);font-size:.69rem;line-height:1.64;margin-bottom:var(--spacing-sm)}}.K1NiCUR8>:not(:last-child){margin-right:var(--padding-x)}.ZNs6L4jZ{align-items:center;display:flex;overflow-x:hidden;overflow-y:visible;padding:var(--padding-y) 0;word-wrap:break-word}.ZNs6L4jZ>:not(:last-child){margin-right:var(--spacing-xxl)}@media(max-width:667.98px){.ZNs6L4jZ{align-items:flex-start;flex-direction:column}.ZNs6L4jZ>:not(:last-child){margin-right:0}}.\\-1-EQ-5x{display:-webkit-box;overflow-x:hidden;overflow-y:visible;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:5}.vepAIo0m{white-space:nowrap}._4w8ynxus,.z2Uj6nWA{align-items:center;display:flex}._4w8ynxus{color:inherit;padding:var(--padding-y) var(--spacing-xs)}@media(max-width:667.98px){._4w8ynxus{padding:.63rem var(--spacing-xs)}}.bIkcGnjp{--size:1.5rem}.R6RuY4Nl{--size:1rem;transform:scaleX(-1)}.HaSuFrFq{margin-left:.4em}._4w8ynxus:disabled{cursor:default;opacity:.6}@media(max-width:1023.98px){.VOXgvORE{display:none}}.TkYAfAww{display:flex;flex-direction:column}.su1253Ee{background:var(--color-fg);color:var(--color-bg);min-width:0;padding:0}._5GsI1YG6{--padding:var(--spacing-default);padding:calc(var(--padding)/2) var(--padding)}._5GsI1YG6:first-child{padding-top:var(--padding)}._5GsI1YG6:last-child{padding-bottom:var(--padding)}.hPP7sLt6{--size:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"bar": "K1NiCUR8",
	"barLeft": "ZNs6L4jZ",
	"barHall": "-1-EQ-5x",
	"barDate": "vepAIo0m",
	"barBtn": "_4w8ynxus",
	"barBtns": "z2Uj6nWA",
	"barBtnIcon": "bIkcGnjp",
	"barBtnIconPushRight": "R6RuY4Nl",
	"barBtnText": "HaSuFrFq",
	"barBtnAside": "VOXgvORE",
	"share": "TkYAfAww",
	"sharePopper": "su1253Ee",
	"shareItem": "_5GsI1YG6",
	"shareItemIcon": "hPP7sLt6"
};
module.exports = ___CSS_LOADER_EXPORT___;
